import { render, staticRenderFns } from "./sales.vue?vue&type=template&id=1f69d3e2"
import script from "./sales.vue?vue&type=script&lang=js"
export * from "./sales.vue?vue&type=script&lang=js"
import style0 from "./sales.vue?vue&type=style&index=0&id=1f69d3e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PayCreditModal: require('/home/ubuntu/complete-nuxt-js/components/pay-credit-modal.vue').default,RefundModal: require('/home/ubuntu/complete-nuxt-js/components/refund-modal.vue').default})
